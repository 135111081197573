import GraphRouter from "@rt/nodes/GraphRouter"
import * as THREE from "three"
class GraphUpdater {
    constructor({ graphDoc , target }){
        this.router = new GraphRouter( graphDoc.d, [])
        this.target = target 

        if ( ! this.router.finalNode ) this.router.finalNode = this.router.nodes._finalNode

		if ( ! this.router.finalNode ) {
		
			throw new Error('Graph Material Updater: no final node found in router')
		}
		window.g = this 

        
    }
    update( dt ){
        const { target, router } = this 
        router.update(dt)

      
        const inputs  = router.finalNode.inputs.getObj()

		for (let key in inputs ) {
            if ( !this.router.finalNodeConnectedInputList[ key ] ) {
				continue 
			}
			const newVal = router.finalNode.inputs.get(key)

            if ( ["position","rotation","scale"].includes( key ) ){
                if ( key === "rotation" ){
                    target.cont3D.rotation.set(
                        newVal.x, newVal.y, newVal.z
                    )
                } else target.cont3D[ key ].copy( newVal )
            }
			const oldVal = target.inputs.get(key)
			// only update VisComp if value has changed (in case the parameter is expensive to change, like an object count)
			if (newVal !== oldVal) target.inputs.set(key, newVal)
		}



		
    }
}
export default GraphUpdater