import merge from 'lodash/merge';
import cloud from './VJYCloudClient';
import typeMan from './TypeManager';
import Sequence from '@data-trans/pattern/Pattern';
import cloneDeep from 'lodash/cloneDeep';
import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';
import extAssetCache from '@three-extra/asset/ExtAssetCache';
import * as THREE from 'three'

const solved = Symbol('link resolved');


const alreadyInstantiatedTypes  = [
	'string',
	'int',
	'float',
	'Vector2',
	'Vector3',
	'Vector4',
	'Quaternion',
	'Color'
]

class ObjectManager {
	constructor() {

	}

	/*
	// USED BY  Component.set( )
	linkToObj(link){
		if(link.level==1){
			let doc = cloud.getDoc(link);
			if (doc) return this.docToObj(doc);
			else return link;
		}else{
			let ret={}
			merge(ret,link);
			ret.level--;
			return ret;
		}
	}
	

	docToObj(doc) {
		console.log("docToObj",doc);
		let ret;
		ret._id = doc._id;
		ret['>link'] = {by:"id",type: doc.t, t: doc.t, id: doc._id, solved: true};
		return ret;
	}
	*/

	/// DESERIALIZATION ///////////////////////////////////////////////////////////////////////////
	/*
		typeDecl       val 

		dynamic
		t:Dynamic    : anything

		non-gen
		t:{cat:2}    : >link
		t:{cat:1}    : "#color", { }
		
		generic
		t:{Array}    : [ ]
		t:{Pattern}  : >link
		t:{Sequence} : >link
	*/

	deserialize(val, typeDecl, path = '') {
		//console.log(">> Deser",val,typeDecl);
		if (!val) return val;
		
		// No typeDecl ... eg. Dynamic - Old Logic
		if (!typeDecl || typeDecl.type === 'Dynamic') {
			//console.log("Deser No T",val,typeDecl);
			// None Object > return
			if (!val || typeof val !== 'object') return val;
			// Higher Class but not Array
			if (val.constructor !== Object && !Array.isArray(val)) return val;
			// {>obj} {>link}
			if (!Array.isArray(val)) {
				if (val['>link']) {
					if (!val['>link'][solved]) return this.solveLink(val, typeDecl); 
					return val;
				}
			}
			//Typeless container: Object or Array
			let keys, ret;
			if (Array.isArray(val)) {
				ret = [];
				keys = val.keys();
			} else {
				ret = {};
				keys = Object.keys(val);
			}

			for (const i of keys) {
				ret[i] = this.deserialize(val[i], null, path + '.' + i);
			}
			return ret;
		} else {
			let typeDef = typeMan.getTypeDef(typeDecl.type);
			
			//console.log("Deser T",val,typeDecl,typeDef);
			if (!typeDef) typeDef = {category: 1};
			switch (typeDef.category){
				// 0 primitive ////////////////////////
				case 0: return val;
				
				// 1 basic ///////////////////////////
				case 1:
					//Go and solve Array elements
					if(typeDecl.type=="Array" && typeDecl.level == 1){
						let ret = [];
						for(var i=0;i<val.length;i++) ret.push(this.solveLink(val[i]));
						return ret;
					}else{
						const factory = typeMan.getClass(typeDecl.type);
						return factory ? factory(val) : val;
					}
				
				// 2 custom ////////////////////////
				case 2:
					const link = val['>link'];
					if(link.solved) return val;
					// If Type is generic
					if (typeDef.isGeneric){
						//If linked doc is not generic > convert to generic
			
						const def = typeMan.getTypeDef(cloud.getDoc(val).t);
					
						if (def === undefined || !def.isGeneric) {
							switch (typeDecl.type) {
								case 'Pattern':
								case 'Sequence':
									let ret = new Sequence({
										elems: [this.solveLink(val, {level: typeDecl.level, type: def? def.type : typeDecl.type })],
									});
									ret._id = ret.elems[0]._id;
									ret['>link'] = ret.elems[0]['>link'];
									return ret;
								case 'Array':
									return [this.solveLink(val, {level: typeDecl.level, type: def.type})];
								default:
									break;
							}
						} else {
							return this.solveLink(val, typeDecl);	
						}
					} else {
						return this.solveLink(val, typeDecl); 
					}
					break;
			}
		}

	}
	instantiatePattern( pattern ){

	}

	solveLink(obj, typeDecl) {
		//let force=false;
		//if (typeof obj['>link'].level !== 'number') obj['>link'].level = 1;
		//console.log("SOLVE LINK > ",obj,typeDecl);
		
		//let { level = 1 } = typeDecl || {};

		let level = 1;
		if(typeDecl && typeDecl.level) level = typeDecl.level;
		//if (!typeDecl && obj['>link'].level) level = obj['>link'].level;
		if (level == 1) {
			//console.log("SOLVE LINK > deserialize");
			let doc = cloud.getDoc(obj);
			if(doc==null){
				//console.warn("SOLVE LINK > NO DOC on client for",obj);
				return null;
			}
			let def = typeMan.getTypeDef(doc.t);
			//console.log("SOLVE LINK > doc",doc,def);
			if (doc) {
				let ret;
				// Do not instantiate - pure data
				if ( !def || !def.construct || def.construct === "factory") {
					ret = cloneDeep(doc.d);
				} else {
					const d = {};
					const keys = Object.keys(def.properties || {});
					
					if (keys.length === 0) {
						ret = this.instantiateObj({d: doc.d, t: doc.t});	
					}
					//console.log("KEYS",keys);
					for (const prop of keys) {
						if (doc.d[prop]) {
							//console.log(prop);
							d[prop] = this.deserialize(doc.d[prop], def.properties[prop].type);
						}
					}
					ret = this.instantiateObj({d, t: doc.t});
				}
				
				// Add identity
				if ( ret === undefined ) ret = {}
				ret._id = doc._id;
				ret['>link'] = {by: 'id', type: doc.t, t: doc.t, id: doc._id, [solved]: true};
				return ret;
			}
		} else {
			// console.log("SOLVE LINK > keep it --");
		}
		return merge({}, obj);
	}

	// 
	instantiateObj(decl, doProps = true) {
		//console.log('instantiateObj decl', decl);
		if (decl.t === 'Object') return decl.d;
		const classFn = typeMan.getClass(decl.t);
		const def = typeMan.getTypeDef(decl.t);
		//console.log("Inst",classFn,def);
		//console.log(typeMan.classes);
		if (classFn) {
			let { construct } = def;
			if (classFn.isFactory) construct = 'factory';
			let instance;
			//console.log("Inst",construct);
			switch (construct) {
				case 'factory': return decl.d;
				case 'constructor(...args)': return new classFn(...decl.d.args);
				case 'constructor(props)': return new classFn(decl.d);
				case 'set(name,val)':
					instance = new classFn();
					if (doProps) for (const i in decl.d) instance.set(i, decl.d[i]);
					return instance;
				case '[name]=val':
					instance = new classFn();
					if (doProps) for (const i in decl.d) instance[i] = decl.d[i];
					return instance;
				case '3_[name]=val':
					//console.log("Instantioate THREE");
					const Vector3 = typeMan.getClass("Vector3");
					instance = new classFn();
					if (doProps) for (const i in decl.d) {
						switch(i){							
							case "points":
							let points=decl.d[i];
							let points3 = [];
							for(let ii=0;ii<points.length;ii++) {
								let v3 = Vector3({x:0,y:0,z:0});
								v3.copy(points[ii]);
								points3.push(v3);
							}
							instance[i]=points3;
							break;
							case "v0":
							case "v1":
							case "v2":
							case "v3":
							instance[ i ] = Vector3({x:0,y:0,z:0});
							instance[i].copy(decl.d[i])
							break;
							default:
							instance[i] = decl.d[i];
						}
						
					}
					return instance;
				case 'props':
					instance = new classFn();
					if (doProps) instance.props = decl.d;
					return instance;
				case 'params':
					instance = new classFn();
					instance.params = decl.d;
					return instance;
				default:
					break;
			}
		} else {
			return decl.d;
		}
	}

	instantiateComp(_decl, globals, setInputs=true) {
		//console.log("instantiateComp 1",_decl);
		let decl;
		if (_decl['>link']) decl = cloud.getDoc(_decl);
		if (_decl['>obj']) decl = _decl['>obj'];
		if (!decl) decl = _decl;

		const def = typeMan.getTypeDef(decl.t);
		//console.log("InstantiateComp");
		//console.log("  Decl:",decl);
		//console.log("  TypeDef:", def);
		let obj;
		if (def) {
			//console.log("instantiateComp classType:", def.classType);
			switch (def.classType) {
				case 1: // Code
				case 2: // Cloud
					const classFn = typeMan.getClass(decl.t);

					try {
						obj = new classFn();
					} catch ( err ){

						console.log(`Object Manager >>> ${decl.t} is not a constructor`, decl )

						throw err
					}
					
					if (obj.isContainer) {
						if (decl.d.isVisual) obj.isVisual = decl.d.isVisual;
						if (decl.d.components) obj.components = decl.d.components;
					}
					if (obj.isVisual) {
						if (globals.cont3D) {
							obj.container = globals.cont3D
							obj.cont3D = globals.cont3D;
						}
						if (globals.container) {
							obj.container = globals.container;
							obj.cont3D = globals.container;
						}
						if (globals.scene) obj.scene = globals.scene;
						if (globals.renderer) obj.renderer = globals.renderer;
						if (globals.me) obj.me = globals.me;
					}
					break;
				default:
					break;
			}
			obj['>link'] = {by: 'id', type: decl.t, id: decl._id};

			obj['>link'].t = decl.t; // FIXME remove this!
			obj.inputs.typeDef = def;
			if(setInputs) obj.inputs.setObj(decl.d);
			return obj;
		}
	}


	/**
	 * Used in Inputs.getObject - get instantiated values for any type
	 * @param {string} type -
	 * @param {*} obj 
	 * @param {*} param2 
	 * @returns 
	 */
	instantiateObjNew( type, obj , {baseMat, defaultMat, defGeom  }, typeObj ){




		if ( alreadyInstantiatedTypes.includes( type ) ) {
			const inputType = type 
			const el = obj 



			if ( inputType === 'Vector2' ){
				return new THREE.Vector2(
						el.x, el.y
					)
				
			}
			if ( inputType === 'Vector3' ){
		
				
				return	new THREE.Vector3(
						el.x, el.y,  el.z 
					)
				
			}
			if ( inputType === 'Quaternion' ){
				
				return 	new THREE.Quaternion(
						el.x, el.y, el.z , el.w 
					)
				
			}
			return obj;
		}
	
		if ( typeMan.isCompatible( type, 'Geometry' ) ){
			return factoryGeom.build( obj, { geom: defGeom } ) 
		}
		if ( typeMan.isCompatible( type, 'Texture2D' ) ){
			return factoryMat.assetToTexture( obj )
		}
		if ( typeMan.isCompatible( type, 'CanvasTexture' ) ){
			return factoryMat.assetToTexture( obj )
		}
		if ( typeMan.isCompatible( type, 'Material' ) ){
			return 	factoryMat.build({ 
				base:baseMat, 
				def:defaultMat, 
				asset:obj
			})
		}
		if ( type === 'Model' ){
			
			const model =  cloud._extAssetCache.get( obj )
			
			return model 
			
		}
		if ( typeMan.isPatternOrSequence( type ) || type === 'Array'  ){
			const res = []

			// Type we get from the inputs, could be eg Sequence<Material>
			let inputType = type.replace(/Pattern/,'').replace(/Sequence/,'').replace('>','').replace('<','')

			if ( typeObj?.args && typeObj.args[ 0 ] ) inputType = typeObj.args[ 0 ]
			
			if ( typeObj.args ) inputType = typeObj.args[ 0 ].type 
			
			let count = obj.count 
			if ( type === 'Array' ) count = obj.length 

		
			for ( let i = 0; i < count; i ++ ){

				let el ;
				if ( type === 'Array' ) el = obj[ i ]
				else  el = obj.getNext()

				if ( inputType === 'Model' )

				if ( typeMan.isCompatible( inputType, 'Geometry' ) ){

					res.push( this.instantiateObjNew( 'Geometry', el, { defGeom }))
				} else if ( inputType === 'Model' ){
				
						const model =  cloud._extAssetCache.get( el )
						res.push( model )
						
					
				} else if (inputType === 'Color' ){

					res.push( new THREE.Color(el) )

				} else if (  inputType ===  'Texture2D' ) {

					res.push( this.instantiateObjNew( 'Texture2D', el, { baseMat, defaultMat }))

				} else if ( typeMan.isCompatible( inputType, 'Material' ) ){

					res.push( this.instantiateObjNew( 'Material', el , { baseMat, defaultMat }))

				} else if ( alreadyInstantiatedTypes.includes( inputType  ) ){

					if ( inputType === 'Vector2' ){
						res.push( 
							new THREE.Vector2(
								el.x, el.y
							)
						)
					} else if ( inputType === 'Vector3' ){
					
						res.push( 
							new THREE.Vector3(
								el.x, el.y,  el.z 
							)
						)
					} else if ( inputType === 'Quaternion' ){
						res.push( 
							new THREE.Quaternion(
								el.x, el.y,  el.z , el.w 
							)
						)
					} else res.push( el )
				}
			}

			return res 
		}


	}

	/// SERIALIZATION ///////////////////////////////////////////////////////////////////////////
	serialize(obj) {
		let ret;
		if(Array.isArray(obj)) ret=[];
		else ret={};
		const keys = Object.keys(obj);
		//Go through the properties
		for (const prop of keys) {
			//Object
			if (typeof obj[prop] === 'object' && obj[prop]) {
				//Array
				if(Array.isArray(obj[prop])){
					ret[prop] = this.serialize(obj[prop]);
				//Std Object
				} else {
					if (obj[prop]['>link']) {
						ret[prop] = {};
						ret[prop]['>link'] = {by:"id",id:obj[prop]['>link'].id};
						if(obj[prop]['>link'].type) ret[prop]['>link'].type=obj[prop]['>link'].type;
						//if(obj[prop]['>link'].linkId) ret[prop]['>link'].linkId=obj[prop]['>link'].linkId;
						if(obj[prop]['>link'].level) ret[prop]['>link'].level=obj[prop]['>link'].level;
						if(obj[prop]['>link'].srcLevel) ret[prop]['>link'].level=obj[prop]['>link'].srcLevel;
					} else {
						ret[prop] = this.serialize(obj[prop]);
					}
				}
			} else {
				ret[prop] = obj[prop];
			}
		}
		return ret;
	}

	// Helpers /////////////////////////////////////////////////////////////////////////////////
	cloneLink(link, level) {
		if (!link || typeof link !== 'object' || !link['>link']) {
			return link;
		}
		const lnk = link[">link"];
		if (typeof level !== 'number') level = lnk.level;
		const nlnk={level};
		nlnk.by=lnk.by;
		nlnk.id=lnk.id;
		nlnk.type=lnk.type;
		return { ">link":nlnk };
	}
	
}

// export as a singleton
const objMan = new ObjectManager();
export default objMan;
