import { Math as Math3 } from 'three';
import Ctrl from './Ctrl';
import EasingValue from '@data-trans/EasingValue';
import inputManager from '@input/InputManager';
import typeManager from '@cloud/TypeManager';
import cloneDeep from "lodash/cloneDeep"
import * as THREE from "three"


const halfPi = Math.PI / 2;

const rotationModes = ["Orbit", "RotateTarget"]
const zoomModes = ["MoveCamera", "ScaleTarget"]

const targetPos = new THREE.Vector3()

class CentralDisplay extends Ctrl {
	constructor(camera, targetObj) {
		super(camera, targetObj);

		this.speedMultiplier = 0.15;
		this.lookMultiplier = 1.2;
		this.startZ = 10;
		this.speedModifier = 1

		this._rotX = new EasingValue(0, 0, 8, 0.0005);
		this._rotY = new EasingValue(0, 0, 8, 0.0005);
		this.spherical = new THREE.Spherical()


		if (!THREE.Quaternion.prototype.invert) {
			THREE.Quaternion.prototype.invert = function () {

				// quaternion is assumed to have unit length

				return this.conjugate();

			}
		}

		document.addEventListener("keyup", this.onKeyUp.bind(this))



	}

	/**
	 * 
	 * Switches between transforms when a number key is pressed
	 */
	onKeyUp(e) {
		if (!e.code) return;
		return
		if (e.code.indexOf("Digit") < 0) return

		const index = parseInt(e.code.replace("Digit", ""))
		this.start(index % this.transforms.length)

	}
	/**
	 * sets rotation and zoom modes, as well as limits
	 */
	setParams() {
		//
		if (this.paramsSet) return

		this.params = cloneDeep(this.params)


		this.speedMultiplier = this.params.zoomSpeed !== undefined ? this.params.zoomSpeed : 0.15;
		this.lookMultiplier = this.params.lookSpeed !== undefined ? this.params.lookSpeed : 1.2;

		this.rotationMode = rotationModes[this.params.rotationMode || 0]
		this.zoomMode = zoomModes[this.params.zoomMode || 0]

		this.autoRotationDirection = new THREE.Vector2(1, 1)

		this.zoomLimit = this.params.zoomLimit !== undefined ? this.params.zoomLimit : true
		this.zoomLimitMin = this.params.zoomLimitMin !== undefined ? this.params.zoomLimitMin : 0.1
		this.zoomLimitMax = this.params.zoomLimitMax || 1000

		this.rotationLimit = this.params.rotationLimit || false

		this.rotationLimitMin = this.params.rotationLimitMin || {}
		this.rotationLimitMax = this.params.rotationLimitMax || {}


		for (let key in this.rotationLimitMax) this.rotationLimitMax[key] = this.rotationLimitMax[key] * Math.PI / 180
		for (let key in this.rotationLimitMin) this.rotationLimitMin[key] = this.rotationLimitMin[key] * Math.PI / 180

		if (this.rotationMode === "Orbit") {
			// THREE.js spherical is inverted
			this.rotationLimitMax.theta -= Math.PI / 2
			this.rotationLimitMin.theta -= Math.PI / 2
		}


		this.verifyLimits()

		this.paramsSet = true
	}
	/**
	 * provide console warnings if minimums are higher than maximums
	 */
	verifyLimits() {
		return
		for (let key in this.rotationLimitMax) {
			if (this.rotationLimitMin[key] >= this.rotationLimitMax[key]) console.warn("Rotation limit value for " + key + " is higher for the minimum than for the maximum. This will probably cause issues")
		}
		if (this.zoomLimitMax <= this.zoomLimitMin) console.warn("ZoomLimitMax is less than or equal to ZoomLimitMin")
	}


	registerTransform(transform) {

		this.transforms.push(transform)


	}
	updateTransform(transformIndex = 0) {

		this.start(transformIndex)
	}


	setTransform(transformIndex = 0) {


		const transform = this.transforms[transformIndex]
		const camTransform = cloneDeep(transform.camera) || {}
		const targetTransform = cloneDeep(transform.target) || {}

		const { position } = camTransform
		const { rotation, scale } = targetTransform

		const defaultPos = new THREE.Vector3(0, 0, 10)
		const defaultScale = new THREE.Vector3(1, 1, 1)
		const defaultRot = new THREE.Euler()

		// POSITION /////////////:
		let camPos = defaultPos
		if (position) {
			for (let key in position) camPos[key] = position[key]
		}
		this._camera.position.copy(camPos)



		// SCALE ////////////////
		let objScale = defaultScale
		if (scale) {

			for (let key in scale) if (scale[key] > 0) objScale[key] = scale[key]
		}

		if (this.zoomMode === "ScaleTarget") this._targetObj.scale.copy(objScale)

		// ROTATION /////////////////
		let objRot = defaultRot
		if (rotation) {
			for (let key in rotation) objRot[key] = rotation[key] * Math.PI / 180
		}
		if (this.rotationMode === "RotateTarget") {
			this._targetObj.rotation.copy(objRot)
		}

		if (this.rotationMode === "RotateTarget") {

			this._targetRotX = this._targetObj.rotation.x;
			this._targetRotY = this._targetObj.rotation.y;

			this._camera.lookAt(this._targetObj.position)

		}



		this.quat = new THREE.Quaternion().setFromUnitVectors(this._camera.up, new THREE.Vector3(0, 1, 0));
		this.quatInverse = this.quat.clone().invert();
		this.offset = new THREE.Vector3()

		const { offset, quat, spherical } = this

		offset.copy(this._camera.position).sub(this._targetObj.position)

		// rotate offset to "y-axis-is-up" space
		offset.applyQuaternion(quat);

		// angle from z-axis around y-axis
		spherical.setFromVector3(offset);

		this.initSpherical = spherical.clone()


		// this.camZ represents the scale of the target object or the camera distance
		if (this.zoomMode === "ScaleTarget") this.camZ = this._targetObj.scale.z
		if (this.zoomMode === "MoveCamera") {
			this.camZ = this.initSpherical.radius
		}

		this.time = 0
		this.frames = 0

	}
	start(transformIndex) {

		if (!this.transforms) {

			this.transforms = [{
				camera: this.params.cameraTransform,
				target: this.params.targetTransform
			}]

		}


		this.setParams()
		this.setTransform(transformIndex)



	}

	update(dt) {
		if (!this._active) return

		dt = Math.min(dt, 0.128)

		//	if ( inputManager._gamepad.active ){
		const button = inputManager.getButton(7)
		if (this.speedModifier === undefined) this.speedModifier = 1
		if (button && typeof button === "boolean") {
			if (this.speedModifier === 1) this.speedModifier = 4
			else this.speedModifier = 1


		}



		//}


		// Zoom /////////////////////
		if (this.zoomMode === "MoveCamera") this.updateCameraDistance()
		if (this.zoomMode === "ScaleTarget") this.updateTargetScale()

		// Rotation /////////////////////

		if (this.rotationMode === "RotateTarget") this.updateTargetRotation(dt)
		if (this.rotationMode === "Orbit") this.updateOrbit(dt)

		this.time += 0.016
		this.frames++

	}
	goTo(position, quaternion) {
		this.quat = new THREE.Quaternion().setFromUnitVectors(this._camera.up, new THREE.Vector3(0, 1, 0));
		this.quatInverse = this.quat.clone().invert();
		this.offset = new THREE.Vector3()

		const { offset, quat, spherical } = this

		offset.copy(this._camera.position).sub(this._targetObj.position)

		// rotate offset to "y-axis-is-up" space
		offset.applyQuaternion(quat);



		// angle from z-axis around y-axis
		spherical.setFromVector3(offset);

		this.initSpherical = spherical.clone()


		// this.camZ represents the scale of the target object or the camera distance
		if (this.zoomMode === "ScaleTarget") this.camZ = this._targetObj.scale.z
		if (this.zoomMode === "MoveCamera") {
			this.camZ = this.initSpherical.radius
		}

		this.time = 0
		this.frames = 0
	}
	startFromCurrentPosition() {
		this.setActive(true)

		

		this._targetRotX = 0
		this._targetRotY = 0

		this._rotX.set(this._targetRotX);
		this._rotY.set(this._targetRotY);

		const {
			spherical,
			quat,
			quatInverse,
			offset
		} = this


		const targetPos = new THREE.Vector3()
		this._targetObj.getWorldPosition( targetPos )
		offset.copy(this._camera.position).sub( targetPos )

		// rotate offset to "y-axis-is-up" space
		offset.applyQuaternion(quat);

		// angle from z-axis around y-axis
		spherical.setFromVector3(offset);

		spherical.radius = this._camera.position.distanceTo( targetPos )

		// if ( this.frames === 0 ) spherical.copy( this.initSpherical )

		spherical.makeSafe();


		offset.setFromSpherical(spherical);

		// rotate offset back to "camera-up-vector-is-up" space
		offset.applyQuaternion(quatInverse);

		this._camera.position.copy(targetPos).add(offset);
		this._camera.lookAt(targetPos);
		this.camZ = spherical.radius

	}
	updateOrbit(dt) {

		// autoRotationSpeed is in degrees per second

		const dy = this.params.autoRotationSpeed ? this.params.autoRotationSpeed.y * Math.PI / 180 * this.autoRotationDirection.y * dt : 0
		const dx = this.params.autoRotationSpeed ? this.params.autoRotationSpeed.x * Math.PI / 180 * this.autoRotationDirection.x * dt : 0

		let { lookY, lookX } = inputManager

		if (inputManager._gamepad.active) {
			lookX *= this.speedModifier
			lookY *= this.speedModifier
		}


		this._targetRotX = Math3.clamp(dx + lookY * this.lookMultiplier, -halfPi, halfPi);
		this._targetRotY = dy + lookX * this.lookMultiplier;

		this._rotX.set(this._targetRotX);
		this._rotY.set(this._targetRotY);

		const {
			spherical,
			quat,
			quatInverse,
			offset
		} = this


		this._targetObj.getWorldPosition( targetPos )
		offset.copy(this._camera.position).sub( targetPos )

		// rotate offset to "y-axis-is-up" space
		offset.applyQuaternion(quat);

		// angle from z-axis around y-axis
		spherical.setFromVector3(offset);

		const deltaY = this._rotY.get()
		const deltaX = this._rotX.get()
		spherical.theta += deltaY
		spherical.phi += deltaX





		// THREE Spherical.phi is rotation around X axis
		// THREE Spherical.theta is rotation around Y axis

		// usually these are inverted
		// phi is rotation around Y axis, theta around X axis


		if (this.rotationLimit) {
			if (dy) {
				if (spherical.theta <= this.params.rotationLimitMin.phi || spherical.theta >= this.params.rotationLimitMax.phi) {
					this.autoRotationDirection.y *= -1
				}
			}
			if (dx) {
				if (spherical.phi <= this.params.rotationLimitMin.theta + Math.PI / 2 || spherical.phi >= this.params.rotationLimitMax.theta + Math.PI / 2) {
					this.autoRotationDirection.x *= -1

				}
			}

			if (this.params.rotationLimitMin.theta !== undefined) spherical.phi = THREE.Math.clamp(spherical.phi, this.params.rotationLimitMin.theta + Math.PI / 2, this.params.rotationLimitMax.theta + Math.PI / 2)

			if (this.params.rotationLimitMin.phi !== undefined) spherical.theta = THREE.Math.clamp(spherical.theta, this.params.rotationLimitMin.phi, this.params.rotationLimitMax.phi)

			// console.log( spherical.phi )
		}



		if (this.zoomMode === "MoveCamera") spherical.radius = this.camZ
		else spherical.radius = this.initSpherical.radius

		// if ( this.frames === 0 ) spherical.copy( this.initSpherical )

		spherical.makeSafe();


		offset.setFromSpherical(spherical);

		// rotate offset back to "camera-up-vector-is-up" space
		offset.applyQuaternion(quatInverse);

		this._camera.position.copy(targetPos).add(offset);
		this._camera.lookAt(targetPos);

	}
	updateCameraDistance() {

		let { speedZ } = inputManager
		//if ( inputManager._gamepad.active ){
		speedZ *= this.speedModifier

		//}

		this.camZ += speedZ * this.speedMultiplier;

		// camera distance from object should never be lower than 0 regardless of whether a limit is set or not.
		this.camZ = Math.max(this.camZ, 0)

		if (this.zoomLimit) {
			this.camZ = THREE.Math.clamp(this.camZ, this.zoomLimitMin, this.zoomLimitMax)
		}


		// If rotation mode is set to orbit, camera position is set in updateOrbit function
		if (this.rotationMode === "RotateTarget") this._camera.position.setLength(this.camZ)

	}
	updateTargetScale() {

		// if ( this.frames === 0 ) this.camZ = this.params.transform.scale.z

		this.camZ -= inputManager.speedZ * this.speedMultiplier * 0.05;

		this.camZ = Math.max(this.camZ, 0)

		if (this.zoomLimit) {
			this.camZ = THREE.Math.clamp(this.camZ, this.zoomLimitMin, this.zoomLimitMax)
		}

		this._targetObj.scale.setScalar(this.camZ);

	}
	updateTargetRotation(dt) {

		const dy = this.params.autoRotationSpeed ? this.params.autoRotationSpeed.y * Math.PI / 180 * this.autoRotationDirection.y * dt : 0
		const dx = this.params.autoRotationSpeed ? this.params.autoRotationSpeed.x * Math.PI / 180 * this.autoRotationDirection.x * dt : 0

		this._targetRotX = Math3.clamp(this._targetRotX + dx - inputManager.lookY * this.lookMultiplier, -halfPi, halfPi);
		this._targetRotY = this._targetRotY + dy - inputManager.lookX * this.lookMultiplier;

		if (this.rotationLimit) {
			if (dy) {
				if (this._targetRotY <= this.params.rotationLimitMin.phi || this._targetRotY >= this.params.rotationLimitMax.phi) {
					this.autoRotationDirection.y *= -1
				}
			}
			if (dx) {
				if (this._targetRotX <= this.params.rotationLimitMin.theta || this._targetRotY >= this.params.rotationLimitMax.theta) {
					this.autoRotationDirection.x *= -1
				}
			}
			this._targetRotY = THREE.Math.clamp(this._targetRotY, this.params.rotationLimitMin.phi, this.params.rotationLimitMax.phi)
			this._targetRotX = THREE.Math.clamp(this._targetRotX, this.params.rotationLimitMin.theta, this.params.rotationLimitMax.theta)
		}


		this._rotX.set(this._targetRotX);
		this._rotY.set(this._targetRotY);

		this._targetObj.rotation.set(this._rotX.get(), this._rotY.get(), 0, 'XYZ');

		if (this.doRotZ) this._targetObj.rotation.set(this._rotX.get(), 0, this._rotY.get(), 'XYZ');


	}


	setActive = (active) => {
	
		this._active = active;
	}
}

typeManager.registerClass('Ctrl.CentralDisplay', CentralDisplay);

export default CentralDisplay;
