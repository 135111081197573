import typeMan from './TypeManager';
import { getQuery } from './helpers';
import { pruneProps } from '@data-trans/conversion';
import monitor from '@rt/monitoring/Monitor';

export class CloudClient {
	constructor() {
		this._cache = {};
		this._cacheDoc = this._cacheDoc.bind(this);
		window.cc = this
	}

	/**
	 * Init with `config`
	 * @param {CloudClientConfig} config
	 */
	init(config = {}) {
		this._apiBaseUrl = config.apiBaseUrl;
		this._storage = config.storage;
		this._settings = config.settings || {}
		this._settings.includeMeta = this._settings.includeMeta !== undefined ? this._settings.includeMeta : true
		this._settings.loadThumbnails = this._settings.loadThumbnails !== undefined ? this._settings.loadThumbnails : true



		if (config.cloudBaseUrl) {
			this.setStandalone(config.cloudBaseUrl);
		}
		if (this._storage) {
			let expiry = this._storage.getItem('vjy-expiry');
			if (expiry) {
				expiry = new Date(parseInt(expiry, 10));
				if (expiry > new Date()) {
					this._expires = expiry;
					this._token = this._storage.getItem('vjy-token');
					this._user = JSON.parse(this._storage.getItem('vjy-user'));
				}
			}
		}
	}

	/**
	 * Register External Asset Cache
	 * @param {ExtAssetCache} plugin  
	 */
	registerExternalAssetCache(plugin) {
		this._extAssetCache = plugin;
	}

	/**
	 * Authenticate a user, on success store token.
	 * @param {string} username
	 * @param {string} apiPassword
	 * @returns {Promise<object, ApiError>} - The result of the successful
	 * authentication or a server or authentication error.
	 */
	async authenticate(username, apiPassword) {
		const body = { username: username, apiPassword: apiPassword };
		const resAuth = await this._sendRequest('token', body);
		this._token = resAuth.token;
		this._expires = new Date(Date.now() + resAuth.expiresInMinutes * 60 * 1000);
		this._user = { id: resAuth.userId, username };
		if (this._storage) {
			this._storage.setItem('vjy-token', this._token);
			this._storage.setItem('vjy-expiry', this._expires.getTime().toString());
			this._storage.setItem('vjy-user', JSON.stringify(this._user));
		}
		return resAuth;
	}

	/**
	 * Remove stored token and user profile.
	 */
	async deauthenticate() {
		this._token = null;
		this._user = null;
		if (this._storage) {
			this._storage.removeItem('vjy-token');
			this._storage.removeItem('vjy-expiry');
			this._storage.removeItem('vjy-user');
		}
	}

	/**
	 * Get the current browser's identifier (set by the user).
	 */
	getBrowserId() {
		if (this._storage && this._user) {
			return this._storage.getItem('vjy-browser:' + this._user.id);
		}
	}

	/**
	 * Set an identifier for the current browser.
	 */
	setBrowserId(identifier) {
		if (this._storage && this._user) {
			const key = 'vjy-browser:' + this._user.id;
			if (identifier) this._storage.setItem(key, identifier);
			else this._storage.removeItem(key);
		}
	}

	/**
	 * Get info about the current user and the expiration of the token.
	 * @returns {Promise<UserInfo, ApiError>}
	 */
	async getCurrentUser() {
		const user = {};
		const defaultSettingsQuery = { t: 'UserSettings', scope: 'public' };
		let doc = this.getDoc(defaultSettingsQuery);
		if (!doc) {
			const res = await this.find(defaultSettingsQuery, null, { cacheResults: true });
			doc = res.docs && res.docs[0];
		}
		user.settings = (doc && doc.d) || {};

		if (this._token && this._expires > new Date()) {
			const profileQuery = { t: 'User', owner: this._user.id, n: this._user.username };
			let profileDoc = this.getDoc(profileQuery);
			if (!profileDoc) {
				const profileRes = await this.find(profileQuery, null, { cacheResults: true });
				profileDoc = profileRes.docs && profileRes.docs[0];
			}

			const settingsQuery = { t: 'UserSettings', owner: this._user.id, n: this._user.username };
			let settingsDoc = this.getDoc(settingsQuery);
			if (!settingsDoc) {
				const settingsRes = await this.find(settingsQuery, null, { cacheResults: true });
				settingsDoc = settingsRes.docs && settingsRes.docs[0];
			}

			const { profile } = await this.getUser();

			let settingsOverrides;
			const browserId = this.getBrowserId();
			const { overrides } = ((settingsDoc && settingsDoc.d) || {});
			if (browserId && overrides) {
				for (const item of overrides) {
					const { identifier, ...rest } = item || {};
					if (identifier === browserId) settingsOverrides = rest;
				}
			}

			// prepare objects to merge
			pruneProps(user.settings, null, undefined, '');
			pruneProps(settingsDoc, null, undefined, '');
			pruneProps(settingsOverrides, null, undefined, '');

			user.id = this._user.id;
			user.username = this._user.username;
			user.expires = this._expires;
			user.defaultScope = profile.defaultScope;
			user.isAdmin = profile.isAdmin;
			user.profile = (profileDoc && profileDoc.d) || {};
			user.settings = {
				...user.settings,
				...((settingsDoc && settingsDoc.d) || {}),
				browser: {
					...(user.settings.browser || {}),
					...((settingsDoc && settingsDoc.d && settingsDoc.d.browser) || {}),
				},
				...(settingsOverrides || {}),
			};
		}
		return user;
	}

	async convertOwnerLinkToId(query) {
		const { owner } = query || {};
		if (owner && typeof owner === 'object' && owner['>link']) {
			const userDoc = this.getDoc(owner['>link']);
			if (userDoc) {
				query.owner = userDoc.m.owner;
			} else {
				const { docs } = await this.find(owner['>link'], null, { cacheResults: true });
				if (docs[0]) query.owner = docs[0].m.owner;
			}
		}
		return query;
	}

	/**
	 * Find docs in the cloud.
	 * @param {FindQuery|VJYDocLink|string} query
	 * @param {FindOptions} [options]
	 * @param {LocalOptions} [localOptions]
	 * @returns {Promise<ApiDocResult, ApiError>}
	 */
	async find(query, options, localOptions) {

		query = getQuery(query);
		await this.convertOwnerLinkToId(query);
		const res = await this._sendRequest('docs/find', { query, options });
		await this._processResults(res, localOptions);
		monitor.log('CloudClient', 'find', { res, query })
		return res;
	}

	/**
	 * Save new doc(s) to the cloud.
	 * @param {VJYDoc|Array<VJYDoc>} docOrDocs
	 * @param {LocalOptions} [opts] - Options to specify the caching of the results.
	 * @returns {Promise<ApiDocResult, ApiError>}
	 */
	async insert(docOrDocs, opts) {
		const docs = Array.isArray(docOrDocs) ? docOrDocs : [docOrDocs];
		const res = await this._sendRequest('docs/insert', { docs });
		await this._processResults(res, opts);
		return res;
	}

	/**
	 * Update a doc's data in the cloud.
	 * @param {VJYDoc} doc - The doc's metadata is ignored.
	 * @param {LocalOptions} [opts] - Options to specify the caching of the results.
	 * @returns {Promise<ApiDocResult, ApiError>}
	 */
	async updateData(doc, opts) {
		const docs = [{ _id: doc._id, d: doc.d }];
		const res = await this._sendRequest('docs/update', { docs });
		await this._processResults(res, opts);
		return res;
	}

	/**
	 * Update a doc's metadata in the cloud.
	 * @param {VJYDoc} doc - The doc's data is ignored.
	 * @param {object} updates - Properties of the passed object are applied to the
	 * doc's metadata.
	 * @param {LocalOptions} [opts] - Options to specify the caching of the results.
	 * @returns {Promise<ApiDocResult, ApiError>}
	 */
	async updateMeta(doc, updates, opts) {
		const docs = [{ _id: doc._id, m: updates }];
		const res = await this._sendRequest('docs/update', { docs });
		await this._processResults(res, opts);
		return res;
	}

	/**
	 * Update doc(s) in the cloud.
	 * @param {VJYDoc|Array<VJYDoc>} docOrDocs - The doc(s) to update.
	 * @param {LocalOptions} [opts] - Options to specify the caching of the results.
	 * @returns {Promise<ApiDocResult, ApiError>}
	 */
	async update(docOrDocs, opts) {
		const docs = Array.isArray(docOrDocs) ? docOrDocs : [docOrDocs];
		const res = await this._sendRequest('docs/update', { docs });
		await this._processResults(res, opts);
		return res;
	}

	/**
	 * Remove doc(s) from the cloud.
	 * @param {VJYDoc|Array<VJYDoc>} docOrDocs - The doc(s) to remove.
	 * @returns {Promise<ApiRemovalResult, ApiError>}
	 */
	remove(docOrDocs) {
		const docs = Array.isArray(docOrDocs) ? docOrDocs : [docOrDocs];
		return this._sendRequest('docs/remove', { docs });
	}

	/**
	 * Get all tags that are present on docs in the cloud whose type matches `typeDecl`.
	 * @param {TypeDeclaration} typeDecl
	 * @returns {Promise<ApiTagsResult, ApiError>}
	 */
	getTags(typeDecl) {
		return this._sendRequest('tags', { typeDecl });
	}

	/**
	 * @param {string|string[]} types
	 * @returns {Promise<ApiTagsResult, ApiError>}
	 */
	async getTagsByTypenames(types) {
		if (typeof types === 'string') types = [types];
		const res = { tags: {} };
		for (const type of types) {
			const typeDecl = typeMan.stringToDecl(type);
			const { tags } = await this.getTags(typeDecl);
			for (const tag of Object.keys(tags)) {
				res.tags[tag] = (res.tags[tag] || 0) + tags[tag];
			}
		}
		return res;
	}

	/**
	 * Get info about docs in the cloud whose type matches `typeDecl`. 
	 * @param {TypeDeclaration} typeDecl
	 * @returns {Promise<ApiTypeInfoResult, ApiError>}
	 */
	getTypeInfo(typeDecl) {
		return this._sendRequest('type-info', { typeDecl });
	}

	/**
	 * Find docs in the cloud that contain links to `doc`.
	 * @param {VJYDoc} doc
	 * @param {FindOptions} [opts] - Result transformation options. All the
	 * properties other than `meta.include`, `meta.raw`, `data.include` and
	 * `data.raw` are ignored.
	 * @returns {Promise<ApiDocResult, ApiError>}
	 */
	async getDependents(doc, opts) {

		const res = await this._sendRequest('dependents', { type: doc.t, id: doc._id, options: opts });
		res._originalDoc = doc;
		return res
	}

	getCloudinaryConfig() {
		return this.callPluginMethod('cloudinary', 'get-upload-config');
	}

	callPluginMethod(plugin, method, params) {
		return this._sendRequest('plugin', { plugin, method, params });
	}

	createUser(username, profile) {
		return this._sendRequest('users/create', { username, profile });
	}

	removeUser(userId) {
		return this._sendRequest('users/remove', { userId });
	}

	getUser() {
		return this._sendRequest('users/get', {});
	}

	updateUser(userId, updates) {
		return this._sendRequest('users/update', { userId, updates });
	}

	/**
	 * Find a doc in cache.
	 * @param {FindQuery|VJYDocLink|string} queryLike - See {@link getQuery}.
	 * @returns {?VJYDoc}
	 */
	getDoc(queryLike) {
		const query = getQuery(queryLike);
		let { id } = query;
		if (!id) {
			id = Object.keys(this._cache).find(id => this._match(this._cache[id], query));
		}
		return this._cache[id];
	}

	/**
	 * Find docs in cache.
	 * @param {FindQuery} query - The query that is used to filter the docs.
	 * @param {FindOptions} [opts] - Result transformation options. All properties
	 * other than `sort` are ignored.
	 * @returns {Array<VJYDoc>}
	 */
	getDocs(query, opts) {
		opts = opts || {};
		const ids = Object.keys(this._cache).filter(id => this._match(this._cache[id], query));
		const docs = ids.map(id => this._cache[id]);
		if (opts.sort) this._sort(docs, opts.sort);
		return docs;
	}

	/**
	 * Convert a response object received from Cloudinary to a Texture2D doc.
	 * @param {object} res
	 * @returns {VJYDoc}
	 * 
	 */
	cloudinaryTextureResponseToDoc(res) {

		const dimensions = {};
		let url;
		const widthIsPowerOfTwo = ((res.width & (res.width - 1)) === 0);
		const heightIsPowerOfTwo = ((res.height & (res.height - 1)) === 0);
		if (widthIsPowerOfTwo && heightIsPowerOfTwo && res.width && res.height ) {

			url = res.secure_url;
			dimensions.width = res.width.toString();
			dimensions.height = res.height.toString();
		} else if ( res.width && res.height ){

			const area = res.width * res.height;
			let size = 1;
			let sizeSquared = size * size;
			for (; ;) {
				const nextSize = size << 1;
				const nextSizeSquared = nextSize * nextSize;
				if (sizeSquared <= area && area <= nextSizeSquared) {
					if (area > sizeSquared * 1.5) size = nextSize;
					break;
				}
				size = nextSize;
				sizeSquared = nextSizeSquared;
			}
			url = res.secure_url.replace('/image/upload/', '/image/upload/c_scale,w_' + size + ',h_' + size + '/');
			dimensions.originalWidth = res.width.toString();
			dimensions.originalHeight = res.height.toString();
			dimensions.width = size.toString();
			dimensions.height = size.toString();
			dimensions.ratio = (res.width / res.height).toString();
		}
		const idx = res.secure_url.indexOf('/image/upload/');
		const urlThumbnail = res.secure_url.substr(0, idx) + '/image/upload/h_256,w_256,c_pad,b_auto:border_contrast/' + res.public_id;
		let t = 'Texture2D'
		if ( res.secure_url.substring( res.secure_url.length - 4) === '.hdr' ){
			t = 'HDRTexture'
			url = res.secure_url
		}
		return {
			t,
			m: {
				n: res.original_filename,
				fields: {
					...dimensions,
					format: res.format,
					transparent: (res.format === 'png').toString(),
					bytes: res.bytes.toString(),
				},
			},
			d: {
				baseObj: {
					type: 1, // DeclBaseType.Asset
					asset: {
						'>ext': {
							t: 'Image',
							src: 'Cloudinary',
							id: res.public_id,
							url,
							urlThumbnail,
							level: 1,
							type: 0, // Web
						},
					},
				},
			},
		};
	}
	/**
	 * Convert a response object received from Cloudinary to a Model doc.
	 * @param {object} res
	 * @returns {VJYDoc}
	 */
	cloudinaryModelResponseToDoc(res, docType ) {

		console.log( res )
		const url =  res.secure_url;
		let type = 'GLTF'

		if ( url.match(/\.fbx/gi) ) type = 'FBX'
		if ( url.match(/\.obj/gi) ) type = 'OBJ'

		const doc =  {
			t: docType,
			m: {
				n: res.original_filename,
				fields: {
					bytes: res.bytes.toString(),
				},
			},
			d: {
				type,
				asset: {
					">ext": {
						t: "",
						src: "Cloudinary",
						id: res.public_id,
						url
					}
				},
				transform: {
					position: {
						x: 0,
						y: 0,
						z: 0
					},
					rotation: {
						x: 0,
						y: 0,
						z: 0
					},
					scale: {
						x: 1,
						y: 1,
						z: 1
					}
				}
			}
		};
		if ( docType === 'Geometry.Model' ){
			doc.d.selector = {
				"include": [
					1
				]
			}
		}
		return doc 
	}

	/**
	 * Set the cloud client to standalone mode. It will fetch and cache docs from
	 * `cloudBaseUrl`, methods that normally send requests to the REST API will
	 * use the cache instead.
	 * @param {string} cloudBaseUrl
	 */
	setStandalone(cloudBaseUrl) {
		if (this._extAssetCache) {
			this._extAssetCache.setBaseUrl(cloudBaseUrl);
		}
		this._cacheFetch = fetch(cloudBaseUrl + 'cloud/docs.json').then(async res => {
			const docs = await res.json();
			const typeDefs = docs.filter(doc => (doc.t === 'TypeDefinition'));
			typeDefs.forEach(doc => typeMan.addTypeDef(doc.d, null, doc._id));
			await this._processResults({ docs }, null, {
				cacheResults: true,
				cacheTypeDefs: false,
				cacheAssets: true,
			});
		});
		this._standalone = true;
	}


	/**
	 * Send a request to the REST API. In standalone mode the request is
	 * simulated on the cache.
	 * @param {string} route
	 * @param {object} body
	 * @returns {Promise<ApiResult, ApiError>}
	 */
	async _sendRequest(route, body) {
		if (this._standalone) {
			return this._serveFromCache(route, body);
		}

		const init = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body),
			mode: 'cors'
		};
		if (this._token) {
			if (this._expires > new Date()) init.headers['x-access-token'] = this._token;
			else this._token = null;
		}

		let res = await fetch(this._apiBaseUrl + route, init);
		res = await res.json();
		if (res.error) throw res.error;
		return res;
	}

	async _serveFromCache(route, body) {
		if (route === 'docs/find') {
			await this._cacheFetch;
			return {
				docs: this.getDocs(body.query, body.options),
			};
		}
		throw { level: 'CloudClient', message: 'Not implemented' };
	}

	/**
	 * Process the response of a REST API request.
	 * @param {ApiResult} res
	 * @param {LocalOptions} opts - Result transformation options. All the
	 * properties other than `cacheResults`, `cacheTypeDefs` and `cacheAssets`
	 * are ignored. See {@link LocalOptions} for their explanation.
	 * @returns {Promise<?, ApiError>}
	 */
	async _processResults(res, opts) {

		const { docs } = res;
	




		if (!Array.isArray(docs) || docs.length === 0) return;
		opts = this._getDefaultOpts(opts);



		if (opts.cacheResults) {
			docs.forEach(this._cacheDoc);
		}
		if (opts.cacheTypeDefs) {

			const typeToAdd = docs.filter( doc => doc.t === 'TypeDefinition' && doc.d )
			await Promise.all(
				typeToAdd.map(
					doc => typeMan.addTypeDef(doc.d, null, doc._id)
				)
			)
			



			const types = docs.map(doc => doc.t).filter(
				(t, i, array) => (array.indexOf(t) === i)
			);

			await Promise.all(types.map(t => typeMan.findTypeDef(t, true)));
		}
		if (opts.cacheAssets && this._extAssetCache) {
			await Promise.all(docs.map(
				doc => {

					if (doc?.m?.noCacheAssets) {

						return Promise.resolve()
					}

					return doc.d ? this._extAssetCache.load(doc) : Promise.resolve()
				}
			));
		}
	}

	/**
	 * Store `doc` in cache.
	 * @param {VJYDoc} doc
	 */
	_cacheDoc(doc) {
		if (this._cache[doc._id]) {
			if (doc.d) this._cache[doc._id].d = doc.d;
			if (doc.m) this._cache[doc._id].m = doc.m;
		} else {
			this._cache[doc._id] = doc;
		}
	}

	/**
	 * Check if `doc` matches `query`.
	 * @param {VJYDoc} doc
	 * @param {FindQuery} query
	 * @returns {boolean}
	 */
	_match(doc, query) {
		const idMatch = (!query.id || doc._id === query.id);
		const typeMatch = (!query.t || typeMan.isCompatible(doc.t, query.t));
		const nameMatch = (!query.n || doc.m.n === query.n);
		const ownerMatch = (!query.owner || doc.m.owner === query.owner);
		let tagsMatch = true;
		if (query.tags && Array.isArray(query.tags) && query.tags.length > 0) {
			if (!doc.m.tags) tagsMatch = false;
			else tagsMatch = query.tags.every(tag => (doc.m.tags.indexOf(tag) >= 0));
		}
		return (idMatch && typeMatch && nameMatch && ownerMatch && tagsMatch);
	}

	/**
	 * Sort docs in place, by `sortParams`.
	 * @param {Array<VJYDoc>} docs
	 * @param {Array<SortParameter>} sortParams
	 */
	_sort(docs, sortParams) {
		docs.sort((a, b) => {
			for (const param of sortParams) {
				const asc = (typeof param.ascending === 'boolean') ? param.ascending : true;
				const props = param.name.split('.');
				let valA = a;
				let valB = b;

				for (const prop of props) {
					if (typeof valA !== 'object') {
						valA = null;
						break;
					}
					valA = valA[prop];
				}

				for (const prop of props) {
					if (typeof valB !== 'object') {
						valB = null;
						break;
					}
					valB = valB[prop];
				}

				// strict equality
				if (valA === valB) continue;

				// compare numbers
				if (typeof valA === 'number' && typeof valB === 'number') {
					return asc ? (valA - valB) : (valB - valA);
				}

				// compare strings
				if (typeof valA === 'string' && typeof valB === 'string') {
					const opts = { sensitivity: 'base' };
					return asc ? valA.localeCompare(valB, opts) : valB.localeCompare(valA, opts);
				}

				// abstract equality
				// eslint-disable-next-line
				if (valA == valB) continue;

				// compare whatever
				return asc ? (valA < valB ? -1 : 1) : (valB < valA ? -1 : 1);
			}

			return 0;
		});
	}


	/**
	 * Get a new options object which has properties that the client uses and
	 * are missing set to their defaults.
	 * @param {LocalOptions} [opts]
	 * @returns {LocalOptions}
	 */
	_getDefaultOpts(opts = {}) {
		opts = { ...opts };
		if (opts.cacheResults !== false) opts.cacheResults = true;
		if (opts.cacheAssets !== false) opts.cacheAssets = true;
		return opts;
	}
}

// export as a singleton
const cc = new CloudClient();
export default cc;


/**
 * Object similar to MongoDB's query criteria object. Each property represents a
 * filter and they are combined by logical AND.
 * @typedef {Object} FindQuery
 * @property {string|Array<string>} [t] - Type name or array of type names.
 * @property {string} [id] - ObjectID as hex string.
 * @property {string} [n] - Doc's name, it is treated as a regex, case-insensitive.
 * @property {string} [scope] - Doc's scope, `public` or `private`.
 * @property {string} [owner] - User ID of the doc's owner.
 * @property {Array<string>} [tags] - Array of tags.
 * @property {boolean} [subclasses] - Match also the subclasses of type(s) specified
 * in `t`. If not specified, interpreted as `true`.
 */

/**
 * Modifiers applied to the result docs matching a {@link FindQuery}.
 * @typedef {Object} FindOptions
 * @property {number} [limit]
 * @property {number} [skip]
 * @property {SortParameter[]} [sort]
 * @property {boolean} [deps]
 * @property {number} [depth]
 * @property {Inclusion} [include]
 * @property {boolean} [stats]
 */

/**
 * @typedef {Object} SortParameter
 * @property {string} name - Field name in doc to sort by. Dot notation accepted.
 * @property {boolean} [ascending] - Sort in ascending order?
 */

/**
 * @typedef {Object} Inclusion
 * @property {boolean} [m] - Include doc's metadata? Interpreted as `true` if omitted.
 * @property {boolean} [d] - Include doc's data? Interpreted as `true` if omitted.
 * @property {boolean} [gen] - Include doc's generators? Interpreted as `false` if omitted.
 */

/**
 * @typedef {Object} LocalOptions
 * @property {boolean} [cacheResults] - Cache the result docs? Interpreted as `true`
 * if omitted.
 * @property {boolean} [cacheAssets] - Download and cache assets associated with the
 * result docs. Interpreted as `true` if omitted.
 * @property {boolean} [cacheTypeDefs] - Find and cache TypeDefinitions of the result
 * docs' types. Interpreted as `false` if omitted.
 */

/**
 * @typedef {Object} VJYDoc
 * @property {string} _id - MongoDB ObjectID as a 24 byte hex string.
 * @property {string} t - Doc's type.
 * @property {VJYDocMeta} m - Doc's metadata.
 * @property {object} d - Doc's data.
 */

/**
 * @typedef {Object} VJYDocMeta
 * @property {string} n - Doc's name.
 * @property {string} created - Date of creation as an ISO 8601 string.
 * @property {string} modified - Date of last modification as an ISO 8601 string.
 * @property {string} owner - The user ID of the doc's owner.
 * @property {string} scope - The doc's scope, `public` or `private`.
 * @property {Array<string>} [tags] - Tags.
 * @property {object} preview
 */

/**
 * @typedef {Object} VJYDocLink
 * @property {VJYDocLinkBody} >link
 */

/**
 * @typedef {Object} VJYDocLinkBody
 * @property {string} [id] - The ObjectID of the linked doc as a hex string.
 */

/**
 * @typedef {Object} ApiDocResult
 * @property {boolean} success
 * @property {Array<VJYDoc>} result
 */

/**
 * @typedef {Object} ApiRemovalResult
 * @property {boolean} success
 * @property {number} removedCount - The number of docs that have been removed.
 */

/**
 * @typedef {Object} ApiTagsResult
 * @property {boolean} success
 * @property {object} result - An object whose properties are the tags, values are
 * numbers indicating on how many docs the tag was found.
 */

/**
 * @typedef {Object} ApiTypeInfoResult
 * @property {boolean} success
 * @property {object} result - An object whose properties are type names, values are
 * objects containing info about the docs of the corresponding type.
 */

/**
 * Response object from the REST API. If `success` is `true`, other properties may
 * be present depending on the endpoint, otherwise `error` is set.
 * @typedef {Object} ApiResult
 * @property {boolean} success
 * @property {ApiError} [error]
 */

/**
 * @typedef {Object} ApiError
 * @property {number} code
 * @property {string} message - Error message.
 */

/**
 * @typedef {Object} UserInfo
 * @property {string} [id] - User ID.
 * @property {string} [username] - Username.
 * @property {Date} [expires] - Expiration date of the current token.
 * @property {object} [profile] - User's profile.
 * @property {object} settings - User's settings.
 */

/**
 * @typedef {Object} CloudClientConfig
 * @property {string} apiBaseUrl
 * @property {Storage} [storage]
 * @property {string} [cloudBaseUrl]
 */
