import * as THREE from 'three';
import Component from '@rt/Component';
import typeManager from '@cloud/TypeManager';
import objectManager from '@cloud/ObjectManager';
import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';
import cloud from '@cloud/VJYCloudClient';
import musicMeta from '@audio/MusicMeta';

import cloneDeep from 'lodash/cloneDeep'
import CanvasTexture  from './CanvasTexture';
import { inRange } from 'lodash';

window.factoryGeom = factoryGeom
window.factoryMat = factoryMat
window.cloneDeep = cloneDeep 
window.mm = musicMeta
window.cloudClient = cloud


const textAlignValues = [
    'start', 
    'end', 
    'left',
    'right', 
    'center'
]
class TextTexture extends CanvasTexture {

	start() {
        super.start()
        this.drawBg()

        for ( let key in this.inputs._ns ){
            this.inputs.listeners.add( key, this.drawBg )
        }
                




	}
    drawBg = _=>{

        const { ctx } = this 
        let { 
            bgColor,
            bgImage,
            width,
            height 
        } = this.inputs.getObj()

        if ( bgColor ){
            ctx.fillStyle = '#' + bgColor.getHexString()
            ctx.fillRect( 0,0, width, height )
        }
        if ( bgImage ){
            if ( bgImage['>link'].type !== 'Texture2D') {
                alert('Please use a Texture2d for bgImage')
                throw new Error('Unsupported bgIMage type')
            }
            const { url } = bgImage.baseObj.asset['>ext']
            const image = new Image()
            image.setAttribute('crossorigin', 'anonymous');
            
            image.onload = _=>{
                ctx.drawImage( image, 0, 0 , width, height )
                this.drawText() 
            }
            image.src = url 
            return 
            
        }
        this.drawText()

    }
    drawText = () =>{
        
        
        const { ctx } = this 
        let { 
            font,
            text ,
            width,
            height,
            textAlign,
            color,
            fillOrStroke,
            bgColor,
            bgImage
        } = this.inputs.getObj()



       

 
        

       

        textAlign = textAlignValues[ textAlign ]

        
        
        ctx.font = font 
        ctx.textAlign = textAlign

        if ( fillOrStroke === 1 ){
            ctx.fillStyle = 'none'
            ctx.strokeStyle = '#' + color.getHexString()
            ctx.strokeText( text, width / 2 , height / 2  )
        } else {
            ctx.strokeStyle = 'none'
            ctx.fillStyle = '#' + color.getHexString()
            ctx.fillText( text, width / 2 , height / 2  )
        }

        this.needsUpdate = true 


        
      
    }




}

typeManager.registerClass("arthur.TextTexture",TextTexture);
export default TextTexture;
