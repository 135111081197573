import * as THREE from 'three';
import Component from '@rt/Component';
import typeManager from '@cloud/TypeManager';
import objectManager from '@cloud/ObjectManager';
import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';
import cloud from '@cloud/VJYCloudClient';
import musicMeta from '@audio/MusicMeta';
import { Inputs, Outputs } from "@rt/InputsOutputs"
import cloneDeep from 'lodash/cloneDeep'
import TextureConstants from '../constants/textures'

window.factoryGeom = factoryGeom
window.factoryMat = factoryMat
window.cloneDeep = cloneDeep 
window.mm = musicMeta
window.cloudClient = cloud

const {
    MappingModes,
    WrappingModes,
    Types,
    Formats,
    MagnificationFilters,
    MinificationFilters
} = TextureConstants

// https://github.com/mrdoob/three.js/blob/dev/src/textures/Texture.js
// https://github.com/mrdoob/three.js/blob/dev/src/textures/CanvasTexture.js

/**
 * Class for dynamic Geometries
 */
class ReactiveGeometry extends THREE.BufferGeometry {

	constructor(){
        
		super();
        this.inputs = new Inputs();
        this.outputs = new Outputs();
        this.inputs.component = this 
        this.inputs.setAutoUpdate = autoUpdate => {
			

			if (autoUpdate ) {
				const obj = this.inputs.getAll()
				for ( let key in obj ){
					if ( this[ key ] ){
						console.warn(
							`Composition with id ${ this[">link"].id } already has a property ${key} of type ${typeof this[ key ]} that will be overwritten by its inputs. Please update its TypeDefinition or code to avoid errors. For now, composition.${key} will not be set to the value from teh input. `
						)
						continue 
					}
					this[ key ] = obj[ key ]
				}
			} 

			this.inputs.autoUpdate = autoUpdate
		 
		}

		
	}
	start() {
        this.inputs.listeners.add( null, this._inputChanged )


	}
  	/**
	 * Private method, transforms the event data if necessary
	 * @param {*} ev 
	 */
	_inputChanged = ( ev ) => {
	
		const { type   } = ev

		const { baseMat, defaultMat } = this.scene 
		const defGeom = this._defGeom 
	 
		if ( this.inputs.autoUpdate ) this[ type ] = this.inputs.getObject( type , { baseMat, defGeom, defGeom })
		
		this.inputChanged({
			type
		})
		
	}
	/**
	 * Overwritten by child classes
	 * @param {} ev 
	 */
	inputChanged( ev )  {
	}
    update( dt ){}


}


typeManager.registerClass("Geometry",ReactiveGeometry );
export default ReactiveGeometry;
