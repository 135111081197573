import * as THREE from 'three';
import Component from '@rt/Component';
import typeManager from '@cloud/TypeManager';
import objectManager from '@cloud/ObjectManager';
import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';
import cloud from '@cloud/VJYCloudClient';
import musicMeta from '@audio/MusicMeta';

import cloneDeep from 'lodash/cloneDeep'
import CanvasTexture from './CanvasTexture';

const mm = musicMeta 


// P
/**
 *      amplitudo: 0.2
        blur: 0
        channel: 0
        color: "#ffffff"
        damping: false
        dim: 1024
        fade: 1
        lineWidth: 10
        mirror: false
        mul: 1
        name: "WaveBasic"
        radius: 0.3
        repeat: (2) [1, 1]
        shape: "circle"
 */

class MusicWaveCircle extends CanvasTexture {

        start() {
                super.start()

                const { ctx } = this

                const channel = this.inputs.get('channel')
                try {
                       
                        const data = this.inputs.get('damping') ? mm.waves[ channel ].dataDamped : mm.waves[ channel].data;

                } catch( error ){
                        alert('Error creating MusicWaveLine texture, see console for more info. Make sure you have added AV to the asset tags')
                        console.log(`MusicWaveLine couldn't find ${this.inputs.get('damping') ? `mm.waves[ channel ].dataDamped` : `mm.waves[ channel].data`} for channel ${channel}`)
                        throw error
                }
		



        }

        update(dt) {

                const { ctx } = this


               
		ctx.clearRect(0,0, this.inputs.get('width'), this.inputs.get('height'));

           
		ctx.lineWidth = this.inputs.get('lineWidth');
		ctx.strokeStyle = '#' + this.inputs.get('color').getHexString();

                const channel = this.inputs.get('channel')
		const data = this.inputs.get('damping') ? mm.waves[ channel ].dataDamped : mm.waves[ channel].data;
		const mul = this.inputs.get('mul');

                const radius = this.inputs.get('radius')
                const amplitude = this.inputs.get('amplitude')

		ctx.beginPath();
                const dim = this.inputs.get('height')
                ctx.moveTo(0, dim  / 2);
                const circleAlpha0 = this.inputs.get('circleAlpha0')
                const circleAlpha1 = this.inputs.get('circleAlpha1')
                
                const a0 = circleAlpha0 ? circleAlpha0 / 180 * Math.PI : 0;
                const a1 = circleAlpha1 ? circleAlpha1 / 180 * Math.PI : Math.PI * 2;
                const aD = a1 - a0;

          
                for (let i = 0; i < data.length; i++) {
                        const val = this.inputs.get('mirror') ? data[i < data.length / 2 ? i * 2 : (data.length - i) * 2] * mul : data[i] * mul;
                        const alpha = a0 + aD / (data.length - 1) * i;
                        const r = dim * radius + val * dim * amplitude;
                        const x = dim * 0.5 - Math.sin(alpha) * r;
                        const y = dim * 0.5 - Math.cos(alpha) * r;
                        if (i == 0) ctx.moveTo(x, y);
                        else ctx.lineTo(x, y);
                }

                ctx.stroke();
                const blur = this.inputs.get('blur')
		if  ( blur > 0)ctx.filter = 'blur(' + blur + 'px)';
		else ctx.filter = '';
               
        }

        dispose() {

        }
}

typeManager.registerClass("MusicWaveCircle", MusicWaveCircle);
export default MusicWaveCircle;
