import GraphRouter from "@rt/nodes/GraphRouter"
import * as THREE from "three"
class GraphUpdater {
    constructor( target ){
       
        this.target = target 
        this.color = new THREE.Color()
        window.mup = this 


      
    }
    update( data  ){
        const { target, color} = this
        window.updater = this 

  
     
        
		for (let key in data.d ) {
            if ( !data.inputList[key] ) continue 
			const newVal = data.d[ key ]


       
            if (newVal.r !== undefined ) {
				
				this.color.setRGB( newVal.r, newVal.g, newVal.b )
                target.inputs.set(key, '#'+ this.color.getHexString())
                continue 

			} 

            if ( ["position","rotation","scale"].includes( key ) ){
                if ( key === "rotation" ){
                    target.cont3D.rotation.set(
                        newVal.x, newVal.y, newVal.z
                    )
                } else target.cont3D[ key ].copy( newVal )
            }
			const oldVal = target.inputs.get(key)
			// only update VisComp if value has changed (in case the parameter is expensive to change, like an object count)
			if (newVal !== oldVal) target.inputs.set(key, newVal)
		}



		
    }
}
export default GraphUpdater