import * as THREE from 'three';
import GOManager from './GOManager';
import VisComp from '@three-extra/VisComp';
import typeManager from '@cloud/TypeManager';
import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';

class EndlessMatrix extends VisComp {
	constructor() {
		super();
		this.GOMan = new GOManager();
		//this.pattObj = null;
		//this.trackObj = null;
		this.doLookAt = false;
		this.doFade = false;
		this.doDim = false;
		this.buildShiftMode = 1;
		this.moveSpeed = 0.002;
		this.rotSpeed=0;
		this.elemScale=1;
		//this.pattObjChange = this.pattObjChange.bind(this);
	}

	/*pattObjChange() {
		console.log('EM', 'Patt CHG');
		const elems = this.GOMan.elems;
		for (let i = 0; i < elems.length; i++) {
			const declGO = this.pattObj.getNext(elems[i].props);
			elems[i].obj.geometry = declGO.geometry;
			elems[i].obj.material = declGO.material;
		}
	}*/
	start() {
		super.start();

		//geomA
		this._defGeom = new THREE.BoxBufferGeometry(); 
		this.generateGeomA();
		this.inputs.listeners.add('geomA', this.onGeomA.bind(this));
		
		//matA
		this._baseMat = this.scene.baseMat;
		this._defMat = new THREE.MeshNormalMaterial();
		this.generateMatA();
		this.inputs.listeners.add('matA', this.onMatA.bind(this));
		
		//GO Manager		
		this.GOMan.clear();
		this.GOMan.container = this.cont3D;


		this.trackObj = this.me.cont3D;
		const props = this.inputs.getObj();
		for (const el of Object.keys(props)) this[el] = props[el];
		if (typeof this.countX === 'undefined') this.countX = this.count;
		if (typeof this.countY === 'undefined') this.countY = this.count;
		if (typeof this.countZ === 'undefined') this.countZ = this.count;
		if (typeof this.dimX === 'undefined') this.dimX = this.dim;
		if (typeof this.dimY === 'undefined') this.dimY = this.dim;
		if (typeof this.dimZ === 'undefined') this.dimZ = this.dim;
		this.gapX = this.countX == 1 ? 0 : this.dimX / (this.countX - 1);
		this.gapY = this.countY == 1 ? 0 : this.dimY / (this.countY - 1);
		this.gapZ = this.countZ == 1 ? 0 : this.dimZ / (this.countZ - 1);
		console.log("SS",this.elemScale);
		//this.pattObj.onChange = this.pattObjChange;
		let i=0;
		for (let x = 0; x < this.countX; x++) {
			for (let y = 0; y < this.countY; y++) {
				for (let z = 0; z < this.countZ; z++) {
					//const declGO = this.pattObj.getNext({x: x, y: y, z: z});

					let pos;
					switch (this.rowShiftMode) {
						case 0:
							pos = new THREE.Vector3(
								-this.dimX / 2 + x * this.gapX,
								-this.dimY / 2 + y * this.gapY,
								-this.dimZ / 2 + z * this.gapZ);
							break;
						case 1:
							pos = new THREE.Vector3(
								-this.dimX / 2 + x * this.gapX,
								-this.dimY / 2 + (y + 0.5 * (z % 2)) * this.gapY,
								-this.dimZ / 2 + z * this.gapZ);
							break;
						case 2:
							pos = new THREE.Vector3(
								-this.dimX / 2 + (x + 0.5 * (z % 2)) * this.gapX,
								-this.dimY / 2 + y * this.gapY,
								-this.dimZ / 2 + z * this.gapZ);
							break;
					}
					const mesh = new THREE.Mesh(this._geometries[i % this._geometries.length], this._materials[i % this._materials.length]);
					const obj = this.GOMan.add(
						mesh,
						{position: pos},
						{pos: pos, x: x, y: y, z: z, ani: {on: false}, states: {faded: false}}
					);
					obj.name = this.GOMan.elems.length;
					mesh.scale.set(this.elemScale,this.elemScale,this.elemScale);
					//console.log(mesh.scale);
					i++;
				}
			}
		}
	}

	update(dt) {
		super.update(dt);
		const maxDX = this.gapX * (this.countX / 2); //this.dim / 2 + this.gap;
		const maxDY = this.gapY * (this.countY / 2);
		const maxDZ = this.gapZ * (this.countZ / 2);
		const maxD = Math.max(maxDX, maxDY, maxDZ);
		const shiftDX = this.dimX + this.gapX;
		const shiftDY = this.dimY + this.gapY;
		const shiftDZ = this.dimZ + this.gapZ;
		let rp = this.inputs.get("rotSpeed");
		if(rp!=null) this.rotSpeed = rp;

		// GO through all elems
		for (let i = 0; i < this.GOMan.elems.length; i++) {
			const props = this.GOMan.elems[i].props;
			const ani = this.GOMan.elems[i].props.ani;
			const obj = this.GOMan.elems[i].obj;
			obj.rotation.x+=this.rotSpeed/100;
			obj.rotation.y+=this.rotSpeed/3/100;
			obj.scale.set(this.elemScale,this.elemScale,this.elemScale);
					
			//if(props.z%2==0) {
			const v = (props.y + props.z) % 2 === 0 ? this.moveSpeed : -this.moveSpeed;
			props.pos.x += v;
			obj.position.x += v;
			//}
			// RUN ANIMATIONS ///////////////////////////////////////////////////////////
        	const fade0 = 1.8;
        	// let fade1 = 0.6;
        	const ani2t1 = 0.4;
        	const ani2t2 = 1.5;
			if (ani.on) {
				ani.perc += 0.05;
				switch (ani.type) {
					case 1:
						if (ani.perc >= 1) {
							ani.perc = 1;
							ani.on = false;
							obj.material.opacity = ani.val1;
							if (ani.val1 === 1) obj.material.transparent = obj.material.map != null ? obj.material.map.transparent : false;
						} else {
							obj.material.opacity = ani.val1 === 1 ? ani.perc : 1 - ani.perc;
							obj.material.transparent = true;
						}
						break;
					case 2:
						let v = 0;
						if (ani.perc < ani2t1) {
							v = 1 - ani.perc / ani2t1;
							obj.material.opacity = v; obj.material.transparent = true;
							//obj.scale.set(v,v,v);//obj.material.transparent=false;
						} else {
							if (ani.perc >= ani2t2) {
								v = 1;
								//obj.scale.set(v,v,v);obj.material.transparent=false;
								obj.material.transparent = true;
								ani.on = false;
							}
							v = (ani.perc - ani2t1) / (ani2t2 - ani2t1);
							obj.position.copy(ani.val1);
							//obj.scale.set(v,v,v);obj.material.transparent=false;
							obj.material.opacity = v; obj.material.transparent = true;
						}
						break;
					default:
						break;
				}
			}

			// PUT TO OTHER SIDE  ////////////////////////////////////////////////////////////////
			const dist = props.pos.clone().sub(this.trackObj.position);
			const newP = props.pos;
			let moved = false;
			if (dist.x > maxDX) {
				newP.x -= shiftDX; moved = true;
			}
			if (dist.x < -maxDX) {
				newP.x += shiftDX; moved = true;
			}
			if (dist.y > maxDY) {
				newP.y -= shiftDY; moved = true;
			}
			if (dist.y < -maxDY) {
				newP.y += shiftDY; moved = true;
			}
			if (dist.z > maxDZ) {
				newP.z -= shiftDZ; moved = true;
			}
			if (dist.z < -maxDZ) {
				newP.z += shiftDZ; moved = true;
			}

			//Fade or Set
			if (moved) {
            	if (this.doFade) {
	            	ani.on = true;
	            	ani.type = 2;
	            	ani.val0 = obj.position.clone();
	            	ani.val1 = newP;
	            	ani.perc = 0;
            	} else {
            		obj.position.copy(newP);
            	}
			}

			//Fade if close to camera //////////////////////////////////////////
			if (this.doFade) {
	            const faded = Math.abs(dist.length()) < fade0;
	            if (props.states.faded && !faded) {
	            	ani.on = true;
	            	ani.type = 1;
	            	ani.val0 = 0;
	            	ani.val1 = 1;
	            	ani.perc = 0;
	            }
	            if (!props.states.faded && faded) {
	            	ani.on = true;
	            	ani.type = 1;
	            	ani.val0 = 1;
	            	ani.val1 = 0;
	            	ani.perc = 0;
	            }
	            props.states.faded = faded;
	        }

	        //Dim color by distance ////////////////////////////////////////////
			if (this.doDim) {
	            let actdist = dist.length();
	            if (ani.on && ani.type === 2) {
	            	actdist = obj.position.clone().sub(this.trackObj.position).length();
	            }
	            let op = actdist / maxD; if (op > 1)op = 1;
	            if (op < 0.2) op = 0;
	            else op -= 0.2;
	            op = 100 + Math.round((1 - op) * 155);
	            if(obj.material.color) obj.material.color.setHex(256 * 256 * op + 256 * op + op);
			}

			//Look At Tar //////////////////////////////////////////////////////
			if (this.doLookAt) obj.lookAt(this.trackObj.position);
		} 
	}
	generateGeomA(){
		console.log(" GENERATE GEOM A !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
		const geomA = this.inputs.get('geomA');
		this._geometries=[];
		for(let i=0;i<geomA.count;i++)this._geometries.push(factoryGeom.build(geomA.getNext(),{geom:this._defGeom}));
	}
	onGeomA(event) {
		this.generateGeomA();
		if(this._geometries.length>0){
			const GOMan = this.GOMan;
			for (let i = 0; i < GOMan.elems.length; i++) GOMan.elems[i].obj.geometry = this._geometries[i % this._geometries.length];
		}
	}
	generateMatA(){
		const matA = this.inputs.get('matA');
		this._materials=[];
		for(let i=0;i<matA.count;i++) this._materials.push(factoryMat.build({ base:this._baseMat, def:this._defMat, asset:matA.getNext()}));
	}
	onMatA(event) {
		this.generateMatA();
		const GOMan = this.GOMan;
		for (let i = 0; i < GOMan.elems.length; i++) GOMan.elems[i].obj.material = this._materials[i % this._materials.length];
	}
}
typeManager.registerClass("GenGO.EndlessMatrix", EndlessMatrix);
export { EndlessMatrix };
