import * as THREE from 'three';
import Component from '@rt/Component';
import typeManager from '@cloud/TypeManager';
import objectManager from '@cloud/ObjectManager';
import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';
import cloud from '@cloud/VJYCloudClient';
import musicMeta from '@audio/MusicMeta';

import cloneDeep from 'lodash/cloneDeep'
import CanvasTexture from './CanvasTexture';

const mm = musicMeta 


// P
/**
 *      amplitudo: 0.2
        blur: 0
        channel: 0
        color: "#ffffff"
        damping: false
        dim: 1024
        fade: 1
        lineWidth: 10
        mirror: false
        mul: 1
        name: "WaveBasic"
        radius: 0.3
        repeat: (2) [1, 1]
        shape: "circle"
 */

class MusicWaveLine extends CanvasTexture {

        start() {
                super.start()

                const { ctx } = this

                const channel = this.inputs.get('channel')
               
                try {
                       
                        const data = this.inputs.get('damping') ? mm.waves[ channel ].dataDamped : mm.waves[ channel].data;

                } catch( error ){
                        alert('Error creating MusicWaveLine texture, see console for more info.  Make sure you have added AV to the asset tag')
                        console.log(`MusicWaveLine couldn't find ${this.inputs.get('damping') ? `mm.waves[ channel ].dataDamped` : `mm.waves[ channel].data`} for channel ${channel}`)
                        throw error
                }
		



        }

        update(dt) {

                const { ctx } = this


               
		ctx.clearRect(0,0, this.inputs.get('width'), this.inputs.get('height'));

           
		ctx.lineWidth = this.inputs.get('lineWidth');
		ctx.strokeStyle = '#' + this.inputs.get('color').getHexString();

                const channel = this.inputs.get('channel')
		const data = this.inputs.get('damping') ? mm.waves[ channel ].dataDamped : mm.waves[ channel].data;
		const mul = this.inputs.get('mul');

		ctx.beginPath();
                const dim = this.inputs.get('height')
                ctx.moveTo(0, dim  / 2);
          
                for (let i = 0; i < data.length; i++) {
                        const val = data[i] * mul;
                        ctx.lineTo( dim / data.length * i, dim / 2 + dim / 2 * val);
                        
                }

                ctx.stroke();
                const blur = this.inputs.get('blur')
		if  ( blur > 0)ctx.filter = 'blur(' + blur + 'px)';
		else ctx.filter = '';
               
        }

        dispose() {

        }
}

typeManager.registerClass("MusicWaveLine", MusicWaveLine);
export default MusicWaveLine;
