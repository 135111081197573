import * as THREE from 'three';
import Component from '@rt/Component';
import typeManager from '@cloud/TypeManager';
import objectManager from '@cloud/ObjectManager';
import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';
import cloud from '@cloud/VJYCloudClient';
import musicMeta from '@audio/MusicMeta';

import cloneDeep from 'lodash/cloneDeep'
import CanvasTexture  from './CanvasTexture';

window.factoryGeom = factoryGeom
window.factoryMat = factoryMat
window.cloneDeep = cloneDeep 
window.mm = musicMeta
window.cloudClient = cloud


class CanvasTextureTest extends CanvasTexture {

	start() {
                super.start()
                
                const { ctx } = this 

                ctx.fillStyle = "#FF0000";
                ctx.fillRect(0,0,150,75);

	}

	update(dt) {
                const { ctx } = this 
                ctx.fillStyle = '#' + this.inputs.get('color').getHexString();
                ctx.clearRect(0,0,300,300)
                ctx.fillRect(0,0,Math.floor(Math.random() * 150 ),Math.floor(Math.random() * 150 ),);
	}


}

typeManager.registerClass("CanvasTextureTest",CanvasTextureTest);
export default CanvasTextureTest;
