import * as THREE from 'three';
import Component from '@rt/Component';
import typeManager from '@cloud/TypeManager';
import objectManager from '@cloud/ObjectManager';
import factoryMat from '@three-extra/asset/MaterialManager';
import factoryGeom from '@three-extra/asset/GeometryManager';
import cloud from '@cloud/VJYCloudClient';
import musicMeta from '@audio/MusicMeta';
import { Inputs, Outputs } from "@rt/InputsOutputs"
import cloneDeep from 'lodash/cloneDeep'
import TextureConstants from '../constants/textures'

window.factoryGeom = factoryGeom
window.factoryMat = factoryMat
window.cloneDeep = cloneDeep 
window.mm = musicMeta
window.cloudClient = cloud

const {
    MappingModes,
    WrappingModes,
    Types,
    Formats,
    MagnificationFilters,
    MinificationFilters
} = TextureConstants

// https://github.com/mrdoob/three.js/blob/dev/src/textures/Texture.js
// https://github.com/mrdoob/three.js/blob/dev/src/textures/CanvasTexture.js

/**
 * Class for dynamic CanvasTextures
 * If no update function is provided in child classes, the texture will only update once
 * else texture.needsUpdate will be set to true by MaterialManager in its update loop
 */
class CanvasTexture extends THREE.CanvasTexture {

	constructor(){
        
		super( document.createElement('canvas'));
        this.inputs = new Inputs();
        this.outputs = new Outputs();
		
	}
	start() {
        

        this.mapping = MappingModes[ this.inputs.get( 'mapping' ) ]

        this.wrapS = WrappingModes[ this.inputs.get( 'wrapS' ) ]
        this.wrapT = WrappingModes[ this.inputs.get( 'wrapT' ) ]

        this.magFilter = MagnificationFilters[ this.inputs.get( 'magFilter' ) ]
        this.minFilter = MinificationFilters[ this.inputs.get( 'minFilter' ) ]

        this.format = Formats[ this.inputs.get( 'format' ) ]

        this.type = Types[ this.inputs.get( 'type' ) ]

        this.anisotropy = this.inputs.get( this.anisotropy ) 

        const canvas = this.source.data

        canvas.width = this.inputs.get('width')
        canvas.height = this.inputs.get('height')

        this.ctx = canvas.getContext('2d')

	}
    debug(){
                document.body.appendChild(
                        this.source.data 
                )
                this.source.data.style = `
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 100000000;`
                document.getElementById('canvas').style.display = 'none'
    }


}


typeManager.registerClass("CanvasTexture",CanvasTexture);
export default CanvasTexture;
