import * as THREE from 'three';
//import OBJLoader from 'three-obj-loader';
//import FBXLoader from 'three-fbx-loader';
//import ColladaLoader from 'three-collada-loader';
//import Zlib from 'browserify-zlib-next';
//import Zlib from './inflate.min';
// import '@cloud/THREETypeDef';



class ModelLoader {
	constructor() {

		this.modelsURL = '';
		this.defMat = new THREE.MeshLambertMaterial({color: 0xffffff});
		this.loaders = {}
		window.ml = this 
	}


	registerLoader({ loader, type }){



		if ( type === 'DRACO' ){
			this.loaders[ type ] = {
				loader
			}
			if ( this.loaders['GLTF'] ){
				this.loaders['GLTF'].loader.setDRACOLoader( loader )
			}
			return
		}
		
		this.loaders[ type ] = {
			loader: new loader()
		}
	}

	async load(decl) {
	
		const { type, asset } = decl;
		const { url } = asset['>ext'];
		let loader;
		switch (decl.type) {
			// case 'MeshGen':this._onLoad(new THREE.Mesh(typeManager.instantiate(decl.decl), this.defMat)); break;
			case 'OBJ': 
				loader = this.loaders["OBJ"].loader; 
				break;
				case 'FBX': 
				loader = this.loaders["FBX"].loader; 
				break;
			case 'GLTF': 
				loader = this.loaders["GLTF"].loader; 
				break;
				case 'DRACO': 
				loader = this.loaders["DRACO"].loader; 
				break;
		//	case 'FBX': loader = this.loaderFBX; break;
		//	case 'DAE': loader = this.loaderCollada; break;
			default: break;
		}
		if (loader) {
			const model = await new Promise((resolve, reject) => {
				loader.load(url, resolve, null, reject);
			});
			return this.processModel(decl, model);
		}
		console.warn("No loader found for decl: ", decl )
	}

	processModel(decl, object) {
		//console.log('Process model ********' );
		//console.log("Model: ", object )
		const initModel = object
		if (decl.type == 'DAE' || decl.type === "GLTF") object = object.scene;
		if ( decl.type === 'DRACO' ){
			object.computeVertexNormals();

			object = new THREE.Mesh( object, new THREE.MeshBasicMaterial({ color: 0xff0000 }))
		}
		const elems = [];
		if (decl.selector) {
			
			object.traverse(child => {
				elems.push(child);
			});
			if (decl.selector.exclude) {
				for (let i = 0; i < decl.selector.exclude.length; i++) {
					elems[decl.selector.exclude[i]].visible = false;
					elems[decl.selector.exclude[i]].parent.remove(elems[decl.selector.exclude[i]]);
				}
			}
			if (decl.selector.include) object = elems[decl.selector.include[0]];
		}

		if (decl.transform) {
			let doTrans=false;
			let matr = new THREE.Matrix4();
			let matrStep = new THREE.Matrix4();
			let rotation, scale, position
			if (decl.transform.rotation) {
				if(decl.transform.rotation.x!=0 || decl.transform.rotation.y!=0 || decl.transform.rotation.z!=0){
					rotation = new THREE.Euler(decl.transform.rotation.x/180*Math.PI,decl.transform.rotation.y/180*Math.PI,decl.transform.rotation.z/180*Math.PI)
					matrStep.makeRotationFromEuler( rotation );
					matr.multiply(matrStep);
					doTrans=true;
				}
			}

			if (decl.transform.scale) {
				if(decl.transform.scale.x!=1 || decl.transform.scale.y!=1 || decl.transform.scale.z!=1){
					scale = new THREE.Vector3( decl.transform.scale.x,decl.transform.scale.y,decl.transform.scale.z )
					matrStep.makeScale( scale.x, scale.y, scale.z  );
					matr.multiply(matrStep);
					doTrans=true;
				}
			}
			if (decl.transform.position) {
				if(decl.transform.position.x!=0 || decl.transform.position.y!=0 || decl.transform.position.z!=0){
					position = new THREE.Vector3( decl.transform.position.x,decl.transform.position.y,decl.transform.position.z)
					matrStep.makeTranslation(position.x, position.y, position.z );
					matr.multiply(matrStep);
					doTrans=true;
				}
			}
			

			if(doTrans && object.geometry ){
				object.geometry.applyMatrix(matr);
			} else if ( doTrans ){

				if ( rotation ) object.rotation.copy( rotation )
				if ( position ) object.position.copy( position )
				if ( scale ) object.scale.copy( scale )

				if ( rotation || position || scale ){ // if there is a transform, apply it to the model then put the model ina container so that the transform will be preserved
					const cont = new THREE.Object3D()
					cont.add( object )
					object = cont 
				}
			}
			
		}

		object.userData = object.userData || {}
		const {
			animations,
			cameras,
			userData
		} = initModel
		object.userData = {
			animations,
			cameras,
			userData
		}
	//	console.log('INIT MODEL', initModel )

		return object;
	}

	replaceMat(child) {
		//console.log(child);
		const name = child.name;
	
		if (child instanceof THREE.Mesh) {
			//child.material.map = texture;
			child.material = this.loadingMaterial; //this.matPhong; //this.matSimple; // this.loadMaterial;
		}
	}

}


const modelLoader = new ModelLoader()

export default modelLoader