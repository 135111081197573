import * as THREE from 'three';
class GOManager {
	constructor(container) {
		this.container = container;
		this.elems = [];
	}
	add(decl, trans, props) {
		const el = {decl: decl, props};
		let obj;
		if (decl.obj) obj = decl.obj;
		else obj = new THREE.Mesh(decl.geometry, decl.material);
		this.container.add(obj);
		el.obj = obj;

		if (trans) {
			if (trans.position) obj.position.copy(trans.position);
			if (trans.rotation) obj.rotation.copy(trans.rotation);
			if (trans.scale) obj.scale.copy(trans.scale);
		}
		this.elems.push(el);
		return obj;
	}
	getByXYZ(x, y, z) {
		for (let i = 0; i < this.elems.length; i++) {
			const props = this.elems[i].props;
			if (props.x == x && props.y == y && props.z == z) return i;
		}
		return -1;
	}
	clear() {
		for (let i = 0; i < this.elems.length; i++) this.container.remove(this.elems[i].obj);
		this.elems = [];
	}
}

export {GOManager as default};
