import * as THREE from "three"
export default {
    "MappingModes":[
        THREE.UVMapping,
        THREE.CubeReflectionMapping,
        THREE.CubeRefractionMapping,
        THREE.EquirectangularReflectionMapping,
        THREE.EquirectangularRefractionMapping,
        THREE.CubeUVReflectionMapping,
        THREE.CubeUVRefractionMapping
    ],
    "WrappingModes":[
        THREE.ClampToEdgeWrapping,
        THREE.RepeatWrapping,
        
        THREE.MirroredRepeatWrapping
    ],
    "MagnificationFilters":[
        THREE.LinearFilter,
        THREE.NearestFilter
        
    ],
    "MinificationFilters":[
        THREE.LinearMipmapLinearFilter,
        THREE.NearestFilter,
        THREE.NearestMipmapNearestFilter,
        THREE.NearestMipmapLinearFilter,
        THREE.LinearFilter,
        THREE.LinearMipmapNearestFilter,
        
    ],
    "Types":[
        THREE.UnsignedByteType,
        THREE.ByteType,
        THREE.ShortType,
        THREE.UnsignedShortType,
        THREE.IntType,
        THREE.UnsignedIntType,
        THREE.FloatType,
        THREE.HalfFloatType,
        THREE.UnsignedShort4444Type,
        THREE.UnsignedShort5551Type,
        THREE.UnsignedInt248Type
    ],
    "Formats": [
        THREE.AlphaFormat,
        THREE.RedFormat,
        THREE.RedIntegerFormat,
        THREE.RGFormat,
        THREE.RGIntegerFormat,
        THREE.RGBAFormat,
        THREE.RGBAIntegerFormat,
        THREE.LuminanceFormat,
        THREE.LuminanceAlphaFormat,
        THREE.DepthFormat,
        THREE.DepthStencilFormat,
    ]
}